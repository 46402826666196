<template>
  <div class="serveBindingManage">
    <ds-header title="奖池管理"></ds-header>
    <a-form layout="inline">
      <a-form-item label="奖池ID：">
        <a-input v-model.trim="searchForm.pondId" placeholder="请输入奖池ID" class="inputClass" />
      </a-form-item>
      <a-form-item label="奖池名称：">
        <a-input v-model.trim="searchForm.pondTitle" placeholder="请输入奖池名称" class="inputClass" />
      </a-form-item>
      <a-form-item label="关联状态：">
        <a-select v-model="searchForm.isBinding" style="width: 200px">
          <a-select-option value="">全部</a-select-option>
          <a-select-option :value="1">关联中</a-select-option>
          <a-select-option :value="2">未关联</a-select-option>
        </a-select>
      </a-form-item>
      <span>
        <a-button type="primary" @click="search" style="margin-top: 3px"> <a-icon type="search" />查询 </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm">重置</a-button>
      </span>
    </a-form>
    <div style="padding-top: 15px">
      <a-button type="primary" @click="addJackpot"> <a-icon type="plus" /> 新增 </a-button>
    </div>
    <div style="padding-top: 15px">
      <a-table
        :rowKey="(record, index) => index"
        :columns="table.columns"
        :data-source="table.dataInfo"
        :pagination="table.pagination.total ? table.pagination : false"
        @change="pageChange"
        bordered
        :scroll="{ x: 1500 }"
      >
        <span slot="iconUrl" slot-scope="text, record" class="action">
          <div class="imgContainer">
            <img :src="record.iconUrl" v-if="record.iconUrl"/>
          </div>
        </span>
        <span slot="action" slot-scope="text, record" class="action">
          <a-button class="btnClass" type="link" @click="view(record.id)"> 查看 </a-button>
          <a-button class="btnClass" type="link" @click="edit(record)" :disabled="record.binding || record.pondType === '2'"> 编辑 </a-button>
          <a-button class="btnClass" type="link" @click="delJackpot(record.id)" :disabled="record.binding"> 删除 </a-button>
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
import * as api from "@/api/modularManagement";
import { jackpotList,deleteJackpot } from '@/api/yearEndActivities'
import moment from 'moment';
export default {
  name: "jackpotManagement",
  components: {

  },
  data() {
    return {
      searchForm: {
        pondId: '',
        pondTitle: '',
        isBinding: '',
      },
      table: {
        dataInfo: [],
        columns: [
          {
            title: "奖池ID",
            dataIndex: "id",
            key: "id",
          },
          {
            title: "奖池名称",
            dataIndex: "pondTitle",
            key: "pondTitle",
          },
          {
            title: "奖池类型",
            dataIndex: "pondType",
            key: "pondType",
            customRender(text) {
              return text === '1' ? '固定奖包' : '概率抽奖'
              // return text === '1' ? '年终回馈' : '开工红包'
            },
          },
          {
            title: "关联状态",
            dataIndex: "binding",
            key: "binding",
            customRender(text) {
              return text ? '关联中' : '未关联'
            },
          },
          {
            title: "关联活动ID",
            dataIndex: "bindingId",
            key: "bindingId",
          },
          {
            title: "操作人",
            dataIndex: "updateUserName",
            key: 'updateUserName'
          },
          {
            title: "更新时间",
            dataIndex: "updateTime",
            key: 'updateTime',
            customRender(text) {
              return moment(text).format('YYYY-MM-DD HH:mm:ss')
            },
          },
          {
            title: "操作",
            key: "action",
            width: "260px",
            className: "action-ql-list",
            scopedSlots: { customRender: "action" },
          },
        ],
        //分页数据
        pagination: {
          showSizeChanger: true,
          current: 1,
          pageSize: 10,
          total: 0,
          showQuickJumper: true,
          showTotal: (total) => {
            return `共 ${total} 条`;
          },
        },
      }
    };
  },
  created() {
    this.searchFormList();
  },
  methods: {
    // 搜索
    search() {
      this.table.pagination.current = 1;
      this.searchFormList();
    },

    // 重制
    resetSearchForm() {
      this.table.pagination.current = 1;
      this.table.pagination.pageSize = 10;
      this.searchForm = this.$options.data.call(this).searchForm;
      this.searchFormList();
    },

    // 新增奖池
    addJackpot () {
      this.$router.push({
        path: '/yearEndActivities/jackpotManagement/add',
        query: {
          type: 'add'
        }
      })
    },

    // 编辑
    edit(data) {
      this.$router.push({
        path: '/yearEndActivities/jackpotManagement/add',
        query: {
          id: data.id,
          type: 'edit'
        }
      })
    },
    // 删除
    delJackpot(id) {
      const that = this
      this.$confirm({
        title: '确定要删除该条数据吗?',
        onOk() {
          deleteJackpot(id).then(res => {
            if (res.code === '200') {
              that.$message.success("删除成功！")
              that.searchFormList();
            }
          })
        },
        onCancel() {
          console.log('Cancel');
        },
        class: 'test',
      });
    },
    // 查看
    view(id) {
      this.$router.push({
        path: '/yearEndActivities/jackpotManagement/add',
        query: {
          id: id,
          type: 'view'
        }
      })
    },
    // 列表
    async searchFormList() {
      let data = {};
      data.pondId = this.searchForm.pondId
      data.pondTitle = this.searchForm.pondTitle
      data.isBinding = this.searchForm.isBinding
      data.currentPage = this.table.pagination.current;
      data.pageSize = this.table.pagination.pageSize
      const res = await jackpotList(data);
      if (res.code === '200') {
        this.table.dataInfo = res.data.data;
        this.table.pagination.total = res.data.count;
      }

    },

    //分页操作
    pageChange(option) {
      this.table.pagination.current = option.current;
      this.table.pagination.pageSize = option.pageSize;
      this.searchFormList();
    },
  },
};
</script>

<style lang="scss" scoped>
.btnClass {
  padding: 0px;
  padding-right: 5px;
}
.action a {
  color: #1890ff;
  margin-right: 15px;
}
.action a:hover {
  color: #40a9ff;
}
.inputClass {
  width: 200px;
}
.imgContainer {
  width: 80px;
  height: 80px;
  text-align: center;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
